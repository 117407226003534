body {
	margin: 0;
	height: 100vh;
	font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: "Varela Round", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-bottom: 2rem;
}

a {
	color: #0072bc;
	text-decoration: none;
}
