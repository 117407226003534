.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
	background-color: rgba(159, 199, 227, 0.6);
}
.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
	background-color: rgba(0, 114, 188, 0.9);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small,
.leaflet-oldie .marker-cluster-medium,
.leaflet-oldie .marker-cluster-large {
	background-color: rgb(159, 199, 227);
}
.leaflet-oldie .marker-cluster-small div,
.leaflet-oldie .marker-cluster-medium div,
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(0, 114, 188);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}
.marker-cluster div {
	color: white;
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;
	text-align: center;
	border-radius: 15px;
	font-weight: bold;
}

.marker-cluster span {
	line-height: 30px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
	-webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
	-moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
	-o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	-webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
		-webkit-stroke-opacity 0.3s ease-in;
	-moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
		-moz-stroke-opacity 0.3s ease-in;
	-o-transition: -o-stroke-dashoffset 0.3s ease-out,
		-o-stroke-opacity 0.3s ease-in;
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
